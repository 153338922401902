import React, { useRef } from "react";
import Navbar from "./components/Navbar";
import Welcome from "./pages/Welcome";
import Routes from "./pages/Routes";
import "./App.css";
import Footer from "./components/Footer";

const App = () => {
  const welcomeRef = useRef(null);
  const aboutRef = useRef(null);
  const routesRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToSection = (section) => {
    switch (section) {
      case "welcome":
        welcomeRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "about":
        aboutRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "routes":
        routesRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "contact":
        contactRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Navbar scrollToSection={scrollToSection} />

      <section ref={welcomeRef} id="welcome" className="section">
        <Welcome />
      </section>

      <section ref={routesRef} id="routes" className="section">
        <Routes />
      </section>

      <section ref={aboutRef} id="about" className="section"></section>

      <section ref={contactRef} id="contact" className="section"></section>

      <Footer scrollToSection={scrollToSection} />
    </div>
  );
};

export default App;
