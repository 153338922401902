import React from "react";
import "../styles/Footer.css";
import { ReactComponent as YoutubeIcon } from "../assets/icons/social/youtube.svg";
import { ReactComponent as WhatsAppIcon } from "../assets/icons/social/whatsapp.svg";
import { ReactComponent as InstagramIcon } from "../assets/icons/social/instagram.svg";
import { ReactComponent as TelegramIcon } from "../assets/icons/social/telegram.svg";
import SendButton from "./SendButton";
import Sponsors from "./Sponsors";

const Footer = ({ scrollToSection }) => {
  return (
    <footer className="footer-container">
      <div className="footer-sections">
        <div className="footer-contact">
          <h4>ارتباط با من</h4>
          <form className="contact-form">
            <input type="email" placeholder="ایمیل شما" required />
            <textarea placeholder="... پیام شما" required></textarea>
            <SendButton />
          </form>
        </div>
        <div className="footer-social">
          <h4>شبکه‌های اجتماعی</h4>
          <div className="social-icons">
            <a
              href="https://www.instagram.com/hamid_ghafari"
              target="_blank"
              rel="noopener noreferrer"
              className="instagram"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.whatsapp.com"
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp"
            >
              <WhatsAppIcon />
            </a>
            <a
              href="https://www.youtube.com/@Hamid_ghafari"
              target="_blank"
              rel="noopener noreferrer"
              className="youtube"
            >
              <YoutubeIcon />
            </a>
            <a
              href="https://t.me/hamid_ghafari"
              target="_blank"
              rel="noopener noreferrer"
              className="telegram"
            >
              <TelegramIcon />
            </a>
          </div>
        </div>

        <div className="footer-sponsors">
          <h4>اسپانسرها</h4>
          <Sponsors />
        </div>
      </div>

      <div className="footer-bottom">
        <p>
          &copy; {new Date().getFullYear()} Developed by A. Taheri & K. Farhadi
          | All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
