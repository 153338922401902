import React from "react";

const PakistanIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    id="burial-chamber-quaid"
    {...props}
  >
    <path d="M63.03 58.96h-2.48v-2.78c0-.54-.43-.97-.97-.97h-1.06v-2.78c0-.54-.43-.97-.97-.97h-1.3L52.5 22.59a.963.963 0 0 0-.96-.84H48.6c-.01-1.3-.18-2.55-.47-3.76 0-.02-.01-.04-.02-.06-1.69-6.97-7.78-12.21-15.15-12.64V4.07c0-.54-.43-.97-.97-.97s-.97.43-.97.97v1.21c-7.3.42-13.33 5.57-15.1 12.43-.03.09-.06.17-.07.27-.29 1.21-.46 2.47-.47 3.77h-2.93c-.49 0-.9.36-.96.84L7.75 51.47h-1.3c-.54 0-.97.43-.97.97v2.78H4.42c-.54 0-.97.43-.97.97v2.78H.97a.956.956 0 0 0-.97.96c0 .54.43.97.97.97h62.06a.97.97 0 0 0 0-1.94zM32 7.17c6.31 0 11.69 4.01 13.76 9.61-9.32-2.1-18.55-2.13-27.47-.08 2.07-5.56 7.43-9.53 13.71-9.53zM17.64 18.86c9.31-2.32 18.97-2.3 28.74.07.18.91.28 1.86.29 2.82h-2.35c-.49 0-.9.36-.96.84l-.37 2.72h-1.16v-2.24c0-1.51-1.23-2.74-2.74-2.74-1.51 0-2.74 1.23-2.74 2.74v2.24h-1.61v-2.24c0-1.51-1.23-2.74-2.74-2.74-1.51 0-2.74 1.23-2.74 2.74v2.24h-1.61v-2.24c0-1.51-1.23-2.74-2.74-2.74-1.51 0-2.74 1.23-2.74 2.74v2.24h-1.16l-.37-2.72a.97.97 0 0 0-.96-.84h-2.35c.01-.99.11-1.96.31-2.89zm22.24 4.2v2.24h-1.59v-2.24a.794.794 0 1 1 1.59 0zm-7.08 0v2.24h-1.6v-2.24c0-.44.36-.8.8-.8.44.01.8.36.8.8zm-7.09 0v2.24h-1.59v-2.24c0-.44.36-.8.8-.8a.8.8 0 0 1 .79.8zm-12.39.62h5.52l.37 2.72c.06.48.48.84.96.84h23.66c.49 0 .9-.36.96-.84l.37-2.72h5.52l3.61 27.79H41.52c-.31-7.71-4.21-18.3-9.52-18.3s-9.21 10.59-9.52 18.3H9.7l3.62-27.79zm26.26 27.79H24.42c.3-7.27 3.9-16.36 7.58-16.36s7.28 9.09 7.58 16.36zM7.42 53.41h49.16v1.81H7.42v-1.81zm51.19 5.55H5.39v-1.81H58.6v1.81z"></path>
    <path d="M46.48 29.65h1.91c.54 0 .97-.43.97-.97s-.43-.97-.97-.97h-1.91c-.54 0-.97.43-.97.97.01.54.44.97.97.97zm.96 4.32c-.54 0-.97.43-.97.97s.43.97.97.97h1.91c.54 0 .97-.43.97-.97s-.43-.97-.97-.97h-1.91zm.96 6.25c-.54 0-.97.43-.97.97s.43.97.97.97h1.91c.54 0 .97-.43.97-.97s-.43-.97-.97-.97H48.4zm-7.35-7.46h1.91c.54 0 .97-.43.97-.97s-.43-.97-.97-.97h-1.91c-.54 0-.97.43-.97.97s.43.97.97.97zm.95 4.32c-.54 0-.97.43-.97.97s.43.97.97.97h1.91c.54 0 .97-.43.97-.97s-.43-.97-.97-.97H42zm.97 6.25c-.54 0-.97.43-.97.97s.43.97.97.97h1.91c.54 0 .97-.43.97-.97s-.43-.97-.97-.97h-1.91zm6.33 3.14c-.54 0-.97.43-.97.97s.43.97.97.97h1.91c.54 0 .97-.43.97-.97s-.43-.97-.97-.97H49.3zM15.6 29.65h1.91c.54 0 .97-.43.97-.97s-.43-.97-.97-.97H15.6a.97.97 0 0 0 0 1.94zm.96 4.32h-1.91c-.54 0-.97.43-.97.97s.43.97.97.97h1.91c.54 0 .97-.43.97-.97s-.43-.97-.97-.97zm-.96 6.25h-1.91c-.54 0-.97.43-.97.97s.43.97.97.97h1.91a.97.97 0 0 0 0-1.94zm5.44-7.46h1.91c.54 0 .97-.43.97-.97s-.43-.97-.97-.97h-1.91a.97.97 0 0 0 0 1.94zm-.95 6.26H22c.54 0 .97-.43.97-.97s-.43-.97-.97-.97h-1.91a.97.97 0 0 0 0 1.94zm.94 4.31h-1.91c-.54 0-.97.43-.97.97s.43.97.97.97h1.91c.54 0 .97-.43.97-.97s-.43-.97-.97-.97zm-6.33 3.14h-1.91c-.54 0-.97.43-.97.97s.43.97.97.97h1.91c.54 0 .97-.43.97-.97s-.43-.97-.97-.97z"></path>
  </svg>
);

export default PakistanIcon;
