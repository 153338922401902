import React, { useState } from "react";
import "../styles/SendButton.css";
import { ReactComponent as SendIcon } from "../assets/icons/send.svg";
import { ReactComponent as CheckIcon } from "../assets/icons/check.svg";

const SendButton = () => {
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleClick = () => {
    if (!isSending) {
      setIsSending(true);

      // Simulate sending state
      setTimeout(() => {
        setIsSent(true);
        setIsSending(false);
      }, 2000); // Simulate sending delay
    }
  };

  return (
    <div className="container">
      <button className="button" onClick={handleClick} style={{backgroundColor: isSent ? "green" : "#353434"}}>
        {!isSent ? (
          <>
            <span className={`button-text ${isSending ? "loading" : ""}`}>
              {isSending ? "داره میره" : "بفرست"}
            </span>
            <SendIcon className={`icon-send ${isSending ? "sending" : ""}`} />
          </>
        ) : (
          <>
            <CheckIcon className="icon-check" />
            <span className="button-text">رفت</span>
          </>
        )}
      </button>
    </div>
  );
};

export default SendButton;
