import React from "react";
import SponsorCard from "./SponsorCard";
import "../styles/Sponsors.css";
import KitaRich from "../assets/images/kitarich.svg";
import Redshift from "../assets/images/redshift.svg";
import MatinaCard from "../assets/images/matina-card.png";

const Sponsors = () => {
  return (
    <div className="sponsors-container">
      <div className="oval-container">
        <a
          href="https://matinacard.ir"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SponsorCard
            sLogo={MatinaCard}
            sName="متینا کارت"
            sInfo="اینجا ما غدا میدیم"
          />
        </a>
        <a
          href="https://kitafamily.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SponsorCard
            sLogo={KitaRich}
            sName="کیتاریچ"
            sInfo="اینجا ما غدا میدیم"
          />
        </a>
        <a
          href="https://redshiftsports.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SponsorCard
            sLogo={Redshift}
            sName="رد شیفت"
            sInfo="اینجا ما غدا میدیم"
          />
        </a>
      </div>
    </div>
  );
};

export default Sponsors;
