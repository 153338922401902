import React from "react";
import PropTypes from "prop-types";
import "../styles/CityIcon.css";

const CityIcon = ({ icon: Icon, visited, onClick }) => {
  const fillColor = visited ? "#FFFFFF" : "rgba(255, 255, 255, 0.2)";

  return (
    <div className="city-icon" onClick={onClick} style={{ cursor: "pointer" }}>
      {React.cloneElement(Icon, {
        fill: fillColor,
        className: "city-icon-svg",
      })}
    </div>
  );
};

CityIcon.propTypes = {
  icon: PropTypes.element.isRequired,
  visited: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CityIcon;
