import React from "react";
import PropTypes from "prop-types";
import "../styles/SponsorCard.css";

const SponsorCard = ({ sLogo, sName, sInfo }) => {
  return (
    <div className="card-container">
      <div className="text-container">
        <h4>{sName}</h4>
        <p>{sInfo}</p>
      </div>
      <div className="logo-container">
        <img src={sLogo} alt={sName} />
      </div>
    </div>
  );
};

SponsorCard.propTypes = {
  sLogo: PropTypes.object.isRequired,
  sName: PropTypes.string.isRequired,
  sInfo: PropTypes.string.isRequired,
};

export default SponsorCard;
