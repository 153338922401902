import React from "react";

const NepalIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
    id="nepal"
    {...props}
  >
    <path d="M82.583 41.584a2 2 0 0 0 1.608-.812l5.417-7.334a2 2 0 0 0-.42-2.797c-.018-.013-.038-.02-.056-.034-.01-.007-.012-.014-.023-.021L75 21.18v-6.93a1.986 1.986 0 0 0-.37-1.152c-.004-.006-.003-.011-.007-.016l-9-12.5a2.556 2.556 0 0 0-3.246 0l-9 12.5c-.006.007-.008.015-.013.022A1.987 1.987 0 0 0 53 14.25v6.93l-14.11 9.406c-.011.008-.016.016-.026.024-.017.012-.036.019-.052.031a2 2 0 0 0-.42 2.797l5.417 7.334a2 2 0 0 0 1.608.812h2.743v2.731l-15.198 9.226a.37.37 0 0 0-.044.037c-.035.023-.073.038-.106.063a2 2 0 0 0-.42 2.797l5.417 7.334a2 2 0 0 0 1.608.812h2.743v2.399L22.146 76.44c-.015.007-.019.016-.032.023a1.987 1.987 0 0 0-.35 3.358l9.148 7.199a2 2 0 0 0 1.236.427H35v12.802h-4a4.004 4.004 0 0 0-4 4v4h-3a4.004 4.004 0 0 0-4 4v4h-5a4.004 4.004 0 0 0-4 4v6a1.802 1.802 0 0 0 2 1.75h102a1.802 1.802 0 0 0 2-1.75v-6a4.004 4.004 0 0 0-4-4h-5v-4a4.004 4.004 0 0 0-4-4h-3v-4a4.004 4.004 0 0 0-4-4h-4V87.448h2.852a2 2 0 0 0 1.236-.427l9.148-7.199a1.987 1.987 0 0 0-.356-3.36c-.012-.007-.013-.015-.025-.02l-20.015-9.46v-2.398h2.743a2 2 0 0 0 1.608-.812l5.417-7.334a2 2 0 0 0-.42-2.797c-.033-.025-.07-.04-.106-.063a.379.379 0 0 0-.044-.037L79.84 44.315v-2.731Zm-1.009-4H79.84V34.25h4.197ZM64 5.172l5.096 7.077H58.904ZM57 16.25h14v4H57ZM46.426 37.584l-2.462-3.334h4.196v3.333Zm-6 23-2.462-3.334h4.196v3.333ZM32.84 83.448l-4.065-3.198H35v3.198ZM31 104.25h21.221l-1.52 4H31Zm12-12h-4v-4h4a2 2 0 0 0 0-4h-4v-4h50v4h-4a2 2 0 0 0 0 4h4v4h-4a2 2 0 0 0 0 4h4v4H75v-12a2 2 0 0 0-2-2H55a2 2 0 0 0-2 2v12H39v-4h4a2 2 0 0 0 0-4Zm28-2v10H57v-10ZM44.621 124H15v-3.75h31.141ZM24 116.25v-4h25.181l-1.52 4ZM48.9 124l7.6-19.75h15L79.1 124Zm64.1-3.75V124H83.379l-1.52-3.75Zm-9-8v4H80.339l-1.52-4Zm-7-8v4H77.299l-1.52-4Zm-1.84-20.802H93V80.25h6.224Zm.927-7.198H31.913l12.696-6h38.782Zm-49.927-10v-9h35.68v9Zm41.414-5.667H85.84V57.25h4.197Zm-.723-7.333H41.149l9.885-6h25.932Zm-34.69-10v-9H75.84v9Zm-5.556-13 9-6h16.79l9 6Z"></path>
  </svg>
);

export default NepalIcon;
