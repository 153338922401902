import React from "react";

const IndonesiaIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    id="bali"
    {...props}
  >
    <path
      fill-rule="evenodd"
      d="m13,10h-1.69c-.23-.21-.31-.54-.31-.98,0-.55-.43-1.01-.98-1.02-.55,0-1.01.43-1.02.98-.02,1.18.36,1.99,1,2.54v2.48c0,.55.45,1,1,1h1v2h-2.69c-.23-.21-.31-.54-.31-.98,0-.55-.43-1.01-.98-1.02-.55,0-1.01.43-1.02.98-.02,1.18.36,1.99,1,2.54v2.48c0,.55.45,1,1,1h1v6h-3c-.55,0-1,.45-1,1v2h-1c-.55,0-1,.45-1,1s.45,1,1,1h11v1h-2c-.55,0-1,.45-1,1v3h-2c-.55,0-1,.45-1,1v3h-5c-.55,0-1,.45-1,1s.45,1,1,1h38c.55,0,1-.45,1-1s-.45-1-1-1h-5v-3c0-.55-.45-1-1-1h-2v-3c0-.55-.45-1-1-1h-2v-1h11c.55,0,1-.45,1-1s-.45-1-1-1h-1v-2c0-.55-.45-1-1-1h-3v-6h1c.55,0,1-.45,1-1v-2.48c.64-.55,1.02-1.36,1-2.54,0-.55-.46-.99-1.02-.98-.55,0-.99.46-.98,1.02,0,.44-.08.77-.31.98h-2.69v-2h1c.55,0,1-.45,1-1v-2.48c.64-.55,1.02-1.36,1-2.54,0-.55-.46-.99-1.02-.98-.55,0-.99.46-.98,1.02,0,.44-.08.77-.31.98h-1.69v-2c0-.46-.31-.86-.76-.97-1.17-.29-1.91-1.09-2.28-2.32-.13-.42-.52-.71-.96-.71h-2c-.55,0-1,.45-1,1v26h-8V5c0-.55-.45-1-1-1h-2c-.44,0-.83.29-.96.71-.38,1.23-1.11,2.03-2.28,2.32-.45.11-.76.51-.76.97v2h0Zm23,30H12v2h24v-2h0Zm-3-4H15v2h18v-2h0Zm-3-3h-12v1h12v-1h0Zm-12-3v1H8v-1h10Zm12,0h10v1h-10v-1h0Zm6-2v-6h-6v6h6Zm-18-6h-6v6h6v-6h0Zm-4,2v2c0,.55.45,1,1,1s1-.45,1-1v-2c0-.55-.45-1-1-1s-1,.45-1,1h0Zm18,0v2c0,.55.45,1,1,1s1-.45,1-1v-2c0-.55-.45-1-1-1s-1,.45-1,1h0Zm-14-4h-8v-1h8v1h0Zm12-1h8v1h-8v-1h0Zm4-2v-2h-4v2h4Zm-16-2h-4v2h4v-2h0Zm12-3h6v1h-6v-1h0Zm-12,0v1h-6v-1h6Zm12-2h3v-1.27c-1.25-.5-2.14-1.41-2.7-2.73h-.3v4h0Zm-12,0v-4s-.3,0-.3,0c-.56,1.32-1.46,2.23-2.7,2.73v1.27h3Z"
    ></path>
  </svg>
);

export default IndonesiaIcon;
