import React, { useState } from "react";
import "../styles/Routes.css";
import CityIcon from "../components/CityIcon";
import IranIcon from "../assets/icons/cities/IranIcon";
import IndonesiaIcon from "../assets/icons/cities/IndonesiaIcon";
import NepalIcon from "../assets/icons/cities/NepalIcon";
import ThailandIcon from "../assets/icons/cities/ThailandIcon";
import IndiaIcon from "../assets/icons/cities/IndiaIcon";
import MalaysiaIcon from "../assets/icons/cities/MalaysiaIcon";
import MyanmarIcon from "../assets/icons/cities/MyanmarIcon";
import VietnamIcon from "../assets/icons/cities/VietnamIcon";
import SriLankaIcon from "../assets/icons/cities/SriLankaIcon";
import PakistanIcon from "../assets/icons/cities/PakistanIcon";
import LaosIcon from "../assets/icons/cities/LaosIcon";
import MaldivesIcon from "../assets/icons/cities/MaldivesIcon";
import CambodiaIcon from "../assets/icons/cities/CambodiaIcon";
import BangladeshIcon from "../assets/icons/cities/Bangladesh";
import CountryInfo from "../components/CountryInfo";
import Road from "../assets/icons/road.svg";
import Bike from "../assets/icons/bike.svg";
import IranFlagAnimation from "../assets/animations/countries/iran-flag.json";
import PakistanFlagAnimation from "../assets/animations/countries/pakistan-flag.json";
import IndiaFlagAnimation from "../assets/animations/countries/india-flag.json";
import SriLankaFlagAnimation from "../assets/animations/countries/sri-lanka-flag.json";
import BangladeshFlagAnimation from "../assets/animations/countries/bangladesh-flag.json";
import MaldivesFlagAnimation from "../assets/animations/countries/maldives-flag.json";
import NepalFlagAnimation from "../assets/animations/countries/nepal-flag.json";
import MyanmarFlagAnimation from "../assets/animations/countries/senegal-flag.json";
import VietnamFlagAnimation from "../assets/animations/countries/vietnam-flag.json";
import LaosFlagAnimation from "../assets/animations/countries/laos-flag.json";
import CambodiaFlagAnimation from "../assets/animations/countries/cambodia-flag.json";
import ThailandFlagAnimation from "../assets/animations/countries/thailand-flag.json";
import MalaysiaFlagAnimation from "../assets/animations/countries/malaysia-flag.json";
import IndonesiaFlagAnimation from "../assets/animations/countries/indonesia-flag.json";

const Routes = () => {
  const initialVisitedState = {
    Indonesia: false,
    Malaysia: false,
    Thailand: false,
    Cambodia: false,
    Laos: false,
    Vietnam: false,
    Myanmar: false,
    Nepal: false,
    Maldives: false,
    Bangladesh: false,
    SriLanka: false,
    India: false,
    Pakistan: false,
    Iran: true,
  };

  const countryData = [
    {
      name: "ایران",
      info: "ایران کشور خوبی است",
      animation: IranFlagAnimation,
    },
    {
      name: "پاکستان",
      info: "پاکستان جنوبی ایشیا میں واقع ایک ملک ہے۔",
      animation: PakistanFlagAnimation,
    },
    {
      name: "هند",
      info: "هند دارای یک تاریخ غنی و متنوع است.",
      animation: IndiaFlagAnimation,
    },
    {
      name: "سری‌لانکا",
      info: "سری‌لانکا یک جزیره زیبا در اقیانوس هند است.",
      animation: SriLankaFlagAnimation,
    },
    {
      name: "بنگلادش",
      info: "بنگلادش یک کشور واقع در جنوب آسیا است.",
      animation: BangladeshFlagAnimation,
    },
    {
      name: "مالدیو",
      info: "مالدیو یک مجموعه جزیره ای زیبا در اقیانوس هند است.",
      animation: MaldivesFlagAnimation,
    },
    {
      name: "نپال",
      info: "نپال به عنوان سرزمین کوه‌ها و قله‌ها شناخته می‌شود.",
      animation: NepalFlagAnimation,
    },
    {
      name: "میانمار",
      info: "میانمار کشوری در جنوب شرقی آسیا است.",
      animation: MyanmarFlagAnimation,
    },
    {
      name: "ویتنام",
      info: "ویتنام کشوری با تاریخ غنی و مناظر طبیعی فوق‌العاده است.",
      animation: VietnamFlagAnimation,
    },
    {
      name: "لائوس",
      info: "لائوس کشوری زیبا با جنگل‌ها و رودخانه‌های فراوان است.",
      animation: LaosFlagAnimation,
    },
    {
      name: "کامبوج",
      info: "کامبوج کشوری با معابد باستانی و فرهنگ غنی است.",
      animation: CambodiaFlagAnimation,
    },
    {
      name: "تایلند",
      info: "تایلند به عنوان سرزمین لبخندها شناخته می‌شود.",
      animation: ThailandFlagAnimation,
    },
    {
      name: "مالزی",
      info: "مالزی یک کشور با تنوع فرهنگی و مناظر زیبا است.",
      animation: MalaysiaFlagAnimation,
    },
    {
      name: "اندونزی",
      info: "اندونزی بزرگترین مجمع الجزایر در جهان است.",
      animation: IndonesiaFlagAnimation,
    },
  ];

  const [visitedCities, setVisitedCities] = useState(initialVisitedState);
  const [bikePosition, setBikePosition] = useState(96.5); // Starting position on the right
  const [countryAnimation, setCountryAnimation] = useState(IranFlagAnimation);
  const [countryName, setCountryName] = useState("ایران");
  const [countryInfo, setCountryInfo] = useState("ایران کشور خوبی است");
  const [currentCountryIndex, setCurrentCountryIndex] = useState(0);

  const handleCityClick = (cityName) => {
    let newPosition = bikePosition;

    switch (cityName) {
      case "Iran":
        newPosition = 96.5;
        setCountryAnimation(IranFlagAnimation);
        setCountryName("ایران");
        setCountryInfo("ایران کشور خوبی است");
        break;
      case "Pakistan":
        setCountryAnimation(PakistanFlagAnimation);
        setCountryName("پاکستان");
        setCountryInfo("پاکستان جنوبی ایشیا میں واقع ایک ملک ہے۔");
        newPosition = 89.9;
        break;
      case "India":
        setCountryAnimation(IndiaFlagAnimation);
        setCountryName("هند");
        setCountryInfo("هند دارای یک تاریخ غنی و متنوع است.");
        newPosition = 83.3;
        break;
      case "SriLanka":
        setCountryAnimation(SriLankaFlagAnimation);
        setCountryName("سری‌لانکا");
        setCountryInfo("سری‌لانکا یک جزیره زیبا در اقیانوس هند است.");
        newPosition = 76.7;
        break;
      case "Bangladesh":
        setCountryAnimation(BangladeshFlagAnimation);
        setCountryName("بنگلادش");
        setCountryInfo("بنگلادش یک کشور واقع در جنوب آسیا است.");
        newPosition = 70;
        break;
      case "Maldives":
        setCountryAnimation(MaldivesFlagAnimation);
        setCountryName("مالدیو");
        setCountryInfo("مالدیو یک مجموعه جزیره ای زیبا در اقیانوس هند است.");
        newPosition = 63.4;
        break;
      case "Nepal":
        setCountryAnimation(NepalFlagAnimation);
        setCountryName("نپال");
        setCountryInfo("نپال به عنوان سرزمین کوه‌ها و قله‌ها شناخته می‌شود.");
        newPosition = 56.8;
        break;
      case "Myanmar":
        setCountryAnimation(MyanmarFlagAnimation);
        setCountryName("میانمار");
        setCountryInfo("میانمار کشوری در جنوب شرقی آسیا است.");
        newPosition = 50.1;
        break;
      case "Vietnam":
        setCountryAnimation(VietnamFlagAnimation);
        setCountryName("ویتنام");
        setCountryInfo(
          "ویتنام کشوری با تاریخ غنی و مناظر طبیعی فوق‌العاده است."
        );
        newPosition = 43.7;
        break;
      case "Laos":
        setCountryAnimation(LaosFlagAnimation);
        setCountryName("لائوس");
        setCountryInfo("لائوس کشوری زیبا با جنگل‌ها و رودخانه‌های فراوان است.");
        newPosition = 37;
        break;
      case "Cambodia":
        setCountryAnimation(CambodiaFlagAnimation);
        setCountryName("کامبوج");
        setCountryInfo("کامبوج کشوری با معابد باستانی و فرهنگ غنی است.");
        newPosition = 30.4;
        break;
      case "Thailand":
        setCountryAnimation(ThailandFlagAnimation);
        setCountryName("تایلند");
        setCountryInfo("تایلند به عنوان سرزمین لبخندها شناخته می‌شود.");
        newPosition = 23.8;
        break;
      case "Malaysia":
        setCountryAnimation(MalaysiaFlagAnimation);
        setCountryName("مالزی");
        setCountryInfo("مالزی یک کشور با تنوع فرهنگی و مناظر زیبا است.");
        newPosition = 17.4;
        break;
      case "Indonesia":
        setCountryAnimation(IndonesiaFlagAnimation);
        setCountryName("اندونزی");
        setCountryInfo("اندونزی بزرگترین مجمع الجزایر در جهان است.");
        newPosition = 10.7;
        break;
      default:
        newPosition = bikePosition;
    }

    setVisitedCities({
      Indonesia: false,
      Malaysia: false,
      Thailand: false,
      Cambodia: false,
      Laos: false,
      Vietnam: false,
      Myanmar: false,
      Nepal: false,
      Maldives: false,
      Bangladesh: false,
      SriLanka: false,
      India: false,
      Pakistan: false,
      Iran: false,
      [cityName]: true,
    });

    setBikePosition(newPosition);
  };

  const handlePrevious = () => {
    setCurrentCountryIndex((prevIndex) =>
      prevIndex === 0 ? countryData.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentCountryIndex((prevIndex) =>
      prevIndex === countryData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const { name, info, animation } = countryData[currentCountryIndex];

  return (
    <div className="routes-container">
      <div className="title-section">
        <h1>مسیر ما</h1>
      </div>
      <div className="country-info-container">
        <button className="arrow-button left-arrow" onClick={handlePrevious}>
          &lt;
        </button>
        <div className="country-info-desktop">
          <CountryInfo
            flagAnimation={countryAnimation}
            countryName={countryName}
            info={countryInfo}
          />
        </div>
        <div className="country-info-mobile">
          <CountryInfo
            flagAnimation={animation}
            countryName={name}
            info={info}
          />
        </div>
        <button className="arrow-button right-arrow" onClick={handleNext}>
          &gt;
        </button>
      </div>
      <div className="journey-section">
        <div className="city-icons-container">
          <CityIcon
            icon={<IndonesiaIcon />}
            visited={visitedCities.Indonesia}
            onClick={() => handleCityClick("Indonesia")}
          />
          <CityIcon
            icon={<MalaysiaIcon />}
            visited={visitedCities.Malaysia}
            onClick={() => handleCityClick("Malaysia")}
          />
          <CityIcon
            icon={<ThailandIcon />}
            visited={visitedCities.Thailand}
            onClick={() => handleCityClick("Thailand")}
          />
          <CityIcon
            icon={<CambodiaIcon />}
            visited={visitedCities.Cambodia}
            onClick={() => handleCityClick("Cambodia")}
          />
          <CityIcon
            icon={<LaosIcon />}
            visited={visitedCities.Laos}
            onClick={() => handleCityClick("Laos")}
          />
          <CityIcon
            icon={<VietnamIcon />}
            visited={visitedCities.Vietnam}
            onClick={() => handleCityClick("Vietnam")}
          />
          <CityIcon
            icon={<MyanmarIcon />}
            visited={visitedCities.Myanmar}
            onClick={() => handleCityClick("Myanmar")}
          />
          <CityIcon
            icon={<NepalIcon />}
            visited={visitedCities.Nepal}
            onClick={() => handleCityClick("Nepal")}
          />
          <CityIcon
            icon={<MaldivesIcon />}
            visited={visitedCities.Maldives}
            onClick={() => handleCityClick("Maldives")}
          />
          <CityIcon
            icon={<BangladeshIcon />}
            visited={visitedCities.Bangladesh}
            onClick={() => handleCityClick("Bangladesh")}
          />
          <CityIcon
            icon={<SriLankaIcon />}
            visited={visitedCities.SriLanka}
            onClick={() => handleCityClick("SriLanka")}
          />
          <CityIcon
            icon={<IndiaIcon />}
            visited={visitedCities.India}
            onClick={() => handleCityClick("India")}
          />
          <CityIcon
            icon={<PakistanIcon />}
            visited={visitedCities.Pakistan}
            onClick={() => handleCityClick("Pakistan")}
          />
          <CityIcon
            icon={<IranIcon />}
            visited={visitedCities.Iran}
            onClick={() => handleCityClick("Iran")}
          />
        </div>
        <div className="road-section">
          <img
            src={Bike}
            alt="bike"
            className="bike"
            style={{ left: `${bikePosition}%` }}
          />
          <img src={Road} alt="road" className="road" />
        </div>
      </div>
    </div>
  );
};

export default Routes;
