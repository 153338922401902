import React from "react";

const IranIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    id="azadi-tower"
    {...props}
  >
    <path d="M60.7 60 47 25.8V5h-6.6l-3.1-3.1L32 3l-5.3-1.1L23.6 5H17v20.8L3.3 60H2v2h60v-2h-1.3zm-2.2 0h-3.8L43.6 25.8l1.6.9L58.5 60zM45 24.3l-2-1.1V7h2v17.3zM41 7v15.1l-.4-.2C39 21 38 19.3 38 17.5V5.4L39.6 7H41zm-5-2.8v13.2c0 2.5 1.4 4.9 3.6 6.1l1.5.8L52.6 60h-7.8l-3.7-26.6c-.2-1.2-.7-2.3-1.5-3.2L32 21.5l-7.7 8.7c-.8.9-1.3 2-1.5 3.2L19.1 60h-7.8l11.5-35.6 1.5-.8c2.2-1.2 3.6-3.6 3.6-6.1V4.2L32 5l4-.8zM33.4 31l2.3-2.3 2.5 2.8c.5.6.9 1.3 1 2.1l.5 3.7-6.3-6.3zm2.8 5.6c-2.8-.8-5.7-.8-8.4 0l4.2-4.2 4.2 4.2zm-4.2-7-2.4-2.4 2.4-2.7 2.4 2.7-2.4 2.4zm-3.7-.9 2.3 2.3-6.3 6.3.5-3.7c.1-.8.4-1.5 1-2.1l2.5-2.8zm-4.5 12 1.8-1c4-2.2 8.8-2.2 12.7 0l1.8 1L42.9 60H21.1l2.7-19.3zM24.4 7 26 5.4v12.1c0 1.8-1 3.5-2.6 4.4l-.4.2V7h1.4zM21 7v16.2l-2 1.1V7h2zm-2.2 19.7 1.6-.9L9.3 60H5.5l13.3-33.3z"></path>
    <path d="M31 7h2v4h-2zM31 13h2v2h-2z"></path>
  </svg>
);

export default IranIcon;
