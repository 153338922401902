import React from "react";

const LaosIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 64 64"
    viewBox="0 0 64 64"
    id="pha-that-luang-temple"
    {...props}
  >
    <path
      d="M61,60v-9.3l-2-3v-4.6l1.1-6.8l-3.1-1V32h-2v3.3l-3.1,1l0.7,4.5l-0.8-1c-1.4-1.7-3.1-3.2-5-4.4l-4.9-3.1L40.8,28h-2l-1-4
	h-1.2l0.4-0.8V20h-1.1L35,13.2c0-0.1,0-0.2,0-0.4l1-4.2L33.4,6H33V2h-2v4h-0.4l-2.7,2.7l1,4.2c0,0.1,0,0.2,0,0.4L28.1,20H27v3.2
	l0.4,0.8h-1.2l-1,4h-2l-1.1,4.4l-4.9,3.1c-1.9,1.2-3.6,2.7-5,4.4l-0.8,1l0.7-4.5l-3.1-1V32H7v3.3l-3.1,1L5,43.1v4.6l-2,3V60H2v2h60
	v-2H61z M53,52v-0.7l1.5-2.3h2.9l1.5,2.3V52H53z M57,47h-2v-3h2V47z M56,37.1l1.9,0.6L57.2,42h-2.3l-0.7-4.3L56,37.1z M36.7,28h-9.4
	l0.5-2h8.4L36.7,28z M30.9,12.4l-0.8-3.1L31.4,8h1.2l1.3,1.3l-0.8,3.1c-0.1,0.4-0.1,0.7-0.1,1.1l0.8,6.5h-3.7l0.8-6.5
	C31,13.1,31,12.8,30.9,12.4z M29,22.8V22h6v0.8L34.4,24h-4.8L29,22.8z M26,30h13.2l0.5,2H24.3l0.5-2H26z M13.7,41.1
	c1.3-1.5,2.8-2.9,4.5-3.9l5-3.2h17.4l5,3.2c1.7,1.1,3.2,2.4,4.5,4l2.7,3.3v3.3l-2,3V52h-5v-3.9l1.2-5.8L44,41.3V39h-2v2.3l-3.2,1.1
	l1.2,5.8V52h-5v-3.9l1.2-5.8L33,41.3V39h-2v2.3l-3.2,1.1l1.2,5.8V52h-5v-3.9l1.2-5.8L22,41.3V39h-2v2.3l-3.2,1.1l1.2,5.8V52h-5v-1.3
	l-2-3v-3.3L13.7,41.1z M41.2,43.7l1.8-0.6l1.8,0.6L44.2,47h-2.4L41.2,43.7z M42,49h2v3h-2V49z M30.2,43.7l1.8-0.6l1.8,0.6L33.2,47
	h-2.4L30.2,43.7z M31,49h2v3h-2V49z M19.2,43.7l1.8-0.6l1.8,0.6L22.2,47h-2.4L19.2,43.7z M20,49h2v3h-2V49z M7,47v-3h2v3H7z M8,37.1
	l1.9,0.6L9.2,42H6.8l-0.7-4.3L8,37.1z M5,51.3L6.5,49h2.9l1.5,2.3V52H5V51.3z M5,54h54v6H5V54z"
    ></path>
    <rect width="2" height="2" x="7" y="56"></rect>
    <rect width="2" height="2" x="15" y="56"></rect>
    <rect width="2" height="2" x="23" y="56"></rect>
    <rect width="2" height="2" x="31" y="56"></rect>
    <rect width="2" height="2" x="39" y="56"></rect>
    <rect width="2" height="2" x="47" y="56"></rect>
    <rect width="2" height="2" x="55" y="56"></rect>
  </svg>
);

export default LaosIcon;
