import React from "react";

const SriLankaIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    id="temple-of-the-tooth"
    {...props}
  >
    <path d="M61 60V49h-1v-2.7c0-.3-.1-.6-.2-.9l-.8-2.6c-.1-1.7-1.5-2.9-3.2-2.8-1.5.1-2.7 1.3-2.8 2.8l-.9 2.6c0 .3-.1.6-.1.9V49h-3v-2.8l1-2v-1.5l-1-2V24h2v-3.5L43.3 15h-.9l-8-7H33V4h-2v4h-1.4l-8 7h-.9L13 20.5V24h2v16.8l-1 2v1.5l1 2V49h-3v-2.7c0-.3-.1-.6-.2-.9l-.8-2.6c-.1-1.7-1.5-2.9-3.2-2.8-1.5.1-2.7 1.3-2.8 2.8l-.9 2.6c0 .3-.1.6-.1.9V49H3v11H2v2h60v-2h-1zm-7-13.7c0-.1 0-.2.1-.3l.9-2.8V43c0-.6.4-1 1-1s1 .4 1 1v.2l.9 2.8c0 .1.1.2.1.3V49h-4v-2.7zM44 37v-2h3v2h-3zm-9.6-20 2.9 5H26.7l2.9-5h4.8zm-3.9-2 1.5-3.5 1.5 3.5h-3zM43 24v1.6l-.4.4H40v-2h3zm4 9h-3v-5.6l1-1V24h2v9zm-15-4c-2.2 0-4 1.8-4 4h-2v-5h12v5h-2c0-2.2-1.8-4-4-4zm2 4h-4c0-1.1.9-2 2-2s2 .9 2 2zm6-5h2v5h-2v-5zm-2-4v2H26v-2h12zm-14 2h-2.6l-.4-.4V24h3v2zm-4 1.4V33h-3v-9h2v2.4l1 1zm2 .6h2v5h-2v-5zm2 7v2h-2v-2h2zm2 0h2v2h-2v-2zm4 0h4v2h-4v-2zm6 0h2v2h-2v-2zm4 0h2v2h-2v-2zm2.7-18 6.3 4.5v.5h-9.4l-2.9-5h6zm-3.4-2h-3.7l-2.1-5h.1l5.7 5zm-8.9-5h.1l-2.1 5h-3.7l5.7-5zM15 21.5l6.3-4.5h6l-2.9 5H15v-.5zM17 35h3v2h-3v-2zm-1 8.8v-.5l1-2V39h30v2.2l1 2v.5l-1 2V49H17v-3.2l-1-2zM6 46.3c0-.1 0-.2.1-.3l.9-2.8V43c0-.6.4-1 1-1s1 .4 1 1v.2l.9 2.8c0 .1.1.2.1.3V49H6v-2.7zM5 51h54v9H5v-9z"></path>
    <path d="M35 44c0-1.7-1.3-3-3-3s-3 1.3-3 3v3h6v-3zm-2 1h-2v-1c0-.6.4-1 1-1s1 .4 1 1v1zM37 41h8v2h-8zM37 45h8v2h-8zM19 41h8v2h-8zM19 45h8v2h-8zM7 53h2v5H7zM13 53h2v5h-2zM19 53h2v5h-2zM25 53h2v5h-2zM31 53h2v5h-2zM37 53h2v5h-2zM43 53h2v5h-2zM49 53h2v5h-2zM55 53h2v5h-2z"></path>
  </svg>
);

export default SriLankaIcon;
