import React, { useEffect, useState } from "react";
import "../styles/Welcome.css";
import Lottie from "lottie-react";
import Scroll from "../assets/animations/scroll-down.json";

const Welcome = () => {
  const [showHamidText, setShowHamidText] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 20) {
      // You can adjust this value based on when you want the text to appear
      setShowHamidText(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="welcome-container">
      <div className="welcome-text">
        <span className="word">IRAN</span>
        <span className="word">TO</span>
        <span className="word">INDONESIA</span>
      </div>
      <div className={`hamid-text ${showHamidText ? "visible" : ""}`}>
        <span className="hamid-word">with Hamidreza</span>
      </div>
      <div className="scroll-animation">
        <Lottie animationData={Scroll} loop={true} color="#fff" />
      </div>
    </div>
  );
};

export default Welcome;
