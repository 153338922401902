import React from "react";

const MalaysiaIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 128 128"
    viewBox="0 0 128 128"
    id="petronas-tower"
    {...props}
  >
    <path
      d="M121.43,115.98h-7.49V67.96c0-2.54-1.81-4.67-4.21-5.17v-24.4c0-2.54-1.81-4.67-4.21-5.17v-9.97c0-0.01,0-0.01,0-0.02
	c0-0.23-0.05-0.45-0.13-0.67c-0.02-0.06-0.05-0.11-0.08-0.17c-0.03-0.06-0.05-0.13-0.08-0.18l-6.39-10.28
	c-0.18-0.29-0.41-0.53-0.67-0.74v-5.1c0-1.09-0.88-1.97-1.97-1.97s-1.97,0.88-1.97,1.97v5.1c-0.25,0.21-0.49,0.45-0.67,0.74
	L87.15,22.2c-0.04,0.06-0.05,0.12-0.08,0.18c-0.03,0.06-0.05,0.11-0.08,0.17c-0.08,0.22-0.13,0.44-0.14,0.67c0,0.01,0,0.01,0,0.02
	v5.34c0,1.09,0.88,1.97,1.97,1.97c1.09,0,1.97-0.88,1.97-1.97v-3.37h10.78v7.89H87.93c-2.92,0-5.29,2.37-5.29,5.29v18.79
	c0,1.09,0.88,1.97,1.97,1.97s1.97-0.88,1.97-1.97V38.38c0-0.74,0.6-1.34,1.34-1.34h16.51c0.74,0,1.34,0.6,1.34,1.34v4.57h-7.36
	c-1.09,0-1.97,0.88-1.97,1.97c0,1.09,0.88,1.97,1.97,1.97h7.36v5.92h-11.3c-1.09,0-1.97,0.88-1.97,1.97c0,1.09,0.88,1.97,1.97,1.97
	h11.3v5.92H83.73c-2.92,0-5.29,2.37-5.29,5.29v14.5H54.79v-5.92h18.58c1.09,0,1.97-0.88,1.97-1.97c0-1.09-0.88-1.97-1.97-1.97H54.79
	v-4.57c0-2.91-2.37-5.29-5.29-5.29H27.45v-5.92h11.3c1.09,0,1.97-0.88,1.97-1.97s-0.88-1.97-1.97-1.97h-11.3v-5.92h7.36
	c1.09,0,1.97-0.88,1.97-1.97s-0.88-1.97-1.97-1.97h-7.36v-4.57c0-0.74,0.6-1.34,1.34-1.34H45.3c0.74,0,1.34,0.6,1.34,1.34v18.79
	c0,1.09,0.88,1.97,1.97,1.97c1.09,0,1.97-0.88,1.97-1.97V38.45c0-2.91-2.37-5.29-5.29-5.29H31.65v-7.89h10.78v3.37
	c0,1.09,0.88,1.97,1.97,1.97c1.09,0,1.97-0.88,1.97-1.97v-5.34c0-0.01,0-0.01,0-0.02c0-0.23-0.05-0.45-0.14-0.67
	c-0.02-0.06-0.05-0.11-0.07-0.16c-0.03-0.06-0.05-0.13-0.08-0.19l-6.4-10.28c-0.18-0.29-0.41-0.53-0.67-0.74v-5.1
	c0-1.09-0.88-1.97-1.97-1.97s-1.97,0.88-1.97,1.97v5.1c-0.25,0.21-0.49,0.45-0.67,0.74l-6.4,10.28c-0.04,0.06-0.05,0.13-0.08,0.19
	c-0.03,0.05-0.05,0.11-0.07,0.16c-0.08,0.22-0.13,0.44-0.14,0.67c0,0.01,0,0.01,0,0.02v9.97c-2.4,0.5-4.21,2.63-4.21,5.17v24.4
	c-2.4,0.5-4.21,2.63-4.21,5.17v47.95h-7.49c-1.09,0-1.97,0.88-1.97,1.97c0,1.09,0.88,1.97,1.97,1.97h109.62
	c1.09,0,1.97-0.88,1.97-1.97C123.4,116.86,122.52,115.98,121.43,115.98z M92.38,21.27l3.81-6.13l3.81,6.13H92.38z M37.04,15.21
	l3.81,6.13h-7.62L37.04,15.21z M78.44,92.44l-6.01-6.04h6.01V92.44z M60.8,86.4l-6.02,6.05V86.4H60.8z M23.24,115.98v-9.86H40
	c1.09,0,1.97-0.88,1.97-1.97c0-1.09-0.88-1.97-1.97-1.97H23.24v-5.92h8.87c1.09,0,1.97-0.88,1.97-1.97s-0.88-1.97-1.97-1.97h-8.87
	V86.4h14.79c1.09,0,1.97-0.88,1.97-1.97s-0.88-1.97-1.97-1.97H23.24v-5.92h20.7c1.09,0,1.97-0.88,1.97-1.97
	c0-1.09-0.88-1.97-1.97-1.97h-20.7v-4.57c0-0.74,0.6-1.34,1.34-1.34H49.5c0.74,0,1.34,0.6,1.34,1.34v40.63
	c0,1.09,0.88,1.97,1.97,1.97s1.97-0.88,1.97-1.97V98.04L66.37,86.4h0.5l11.58,11.63v10.55c0,1.09,0.88,1.97,1.97,1.97
	s1.97-0.88,1.97-1.97V97.25c0-0.01,0-0.03,0-0.04V84.45c0-0.01,0-0.01,0-0.02v-9.86c0-0.01,0-0.01,0-0.02v-6.59
	c0-0.74,0.6-1.34,1.34-1.34h24.92c0.74,0,1.34,0.6,1.34,1.34v4.57h-20.7c-1.09,0-1.97,0.88-1.97,1.97s0.88,1.97,1.97,1.97h20.7v5.92
	H95.2c-1.09,0-1.97,0.88-1.97,1.97s0.88,1.97,1.97,1.97h14.79v5.92h-8.87c-1.09,0-1.97,0.88-1.97,1.97c0,1.09,0.88,1.97,1.97,1.97
	h8.87v5.92H93.23c-1.09,0-1.97,0.88-1.97,1.97s0.88,1.97,1.97,1.97h16.76v9.86H80.42c-0.12,0-0.22,0.05-0.33,0.07H23.24z"
    ></path>
  </svg>
);

export default MalaysiaIcon;
