import React from "react";
import PropTypes from "prop-types";
import Lottie from "lottie-react";
import "../styles/CountryInfo.css";

const CountryInfo = ({ flagAnimation, countryName, info }) => {
  return (
    <div className="country-info">
      <div className="text-section">
        <h2 className="country-name">{countryName}</h2>
        <p className="country-info-text">{info}</p>
      </div>
      {/* <div className="separator">
        <span>|</span>
      </div> */}
      <div className="flag-animation">
        <Lottie animationData={flagAnimation} loop={false} />
      </div>
    </div>
  );
};

CountryInfo.propTypes = {
  flagAnimation: PropTypes.object.isRequired, // Lottie animation JSON object
  countryName: PropTypes.string.isRequired, // Name of the country
  info: PropTypes.string.isRequired, // Additional information
};

export default CountryInfo;
