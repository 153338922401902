import React from "react";

const VietnamIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    id="vietnam"
    {...props}
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M38.679 13H23.607l-1.893-2.927L31 5l9.571 5.073L38.679 13zM15 62h33M24.671 16.581 25.143 13M23.748 23.573l.432-3.271M22.571 32.494l.682-5.171M21.223 42.715l.813-6.164M20.042 51.669l.698-5.289M18.679 62l.747-5.659M42.898 56.626 43.607 62M41.548 46.396l.696 5.273M40.215 36.286l.848 6.429M39.032 27.323l.682 5.171M38.106 20.302l.432 3.271M37.143 13l.496 3.758M43.619 56H18.667l-2.096-4h29.143l-2.095 4zM42.22 46.063H20.066L18.205 43h25.876l-1.861 3.063zM41.417 36H20.869l-1.726-3h24l-1.726 3zM39.949 27H22.336l-1.479-3h20.572l-1.48 3zM38.482 20H23.804l-1.233-3h17.143l-1.232 3zM38 62l-5-52M24 62l5-52M31 24v-2M31 32v-2"
    ></path>
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M31.04 39h0a2.04 2.04 0 0 1 2.04 2.04V43h0H29h0v-1.96A2.04 2.04 0 0 1 31.04 39zm0 9h0a2.04 2.04 0 0 1 2.04 2.04V52h0H29h0v-1.96A2.04 2.04 0 0 1 31.04 48zM31 57h0a3 3 0 0 1 3 3v2h0-6 0v-2a3 3 0 0 1 3-3zM23 10h17M31 5V2"
    ></path>
  </svg>
);

export default VietnamIcon;
