import React, { useState, useRef } from "react";
import Lottie from 'lottie-react'; 
import "../styles/Navbar.css";
import hamburgerAnimation from '../assets/animations/Menu.json'


const Navbar = ({ scrollToSection }) => {
  const [isOpen, setIsOpen] = useState(false);
  const lottieRef = useRef();

  // Toggle between menu open/close states
  const toggleMenu = () => {
    if (!isOpen) {
      // Play animation from frame 0 to 17 for opening the menu
      lottieRef.current.goToAndPlay(0, true);

      // Stop at frame 17
      setTimeout(() => {
        lottieRef.current.goToAndStop(17, true); // Stop at frame 17
      }, calculateDuration(0, 17)); // Dynamically calculate time needed to reach frame 17
    } else {
      // Play animation from frame 8 to 55 for closing the menu
      lottieRef.current.goToAndPlay(28, true);
    }
    setIsOpen(!isOpen);
  };

  
  // Calculate the duration to reach the frame based on the total duration and frame numbers
  const calculateDuration = (startFrame, endFrame) => {
    const totalFrames = 55; 
    const totalDuration = 2000; // Total duration of the animation in milliseconds (2 seconds in this case)
    const framesToPlay = endFrame - startFrame;
    const durationPerFrame = totalDuration / totalFrames;

    return framesToPlay * durationPerFrame;
  };

const closeMenu = () => {
  // Play animation from frame 28 to 55 for closing the menu
  lottieRef.current.goToAndPlay(28, true);
  setIsOpen(false);
};

  // Combined function to scroll and close the menu
  const handleMenuClick = (sectionId) => {
    scrollToSection(sectionId);
    closeMenu(); // Close the menu after scrolling
  };

  return (
    <>
      <div className="hamburger" onClick={toggleMenu}>
      <Lottie
          lottieRef={lottieRef}
          animationData={hamburgerAnimation}
          loop={false}
          autoplay={false}
          style={{ height: '50px', width: '50px'}}
          className="hamburger-animation"
        />
      </div>

      {/* Full Screen Menu */}
      <div className={`menu-overlay ${isOpen ? "menu-open" : ""}`}>
        <ul className="navList">
          <li className="navItem" onClick={() => handleMenuClick('welcome')}>
            برنامه الان
          </li>
          <li className="navItem" onClick={() => handleMenuClick('routes')}>
            مسیر ما
          </li>
          <li className="navItem" onClick={() => handleMenuClick('contact')}>
            ارتباط با من
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
